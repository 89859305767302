<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
              Crypto Pricing Treasury
            </b-card-header>
            <b-card-body>
                <b-row>
                  <b-col>
                    <b-form-group id="crypto"
                      label="Asset Kripto"
                      label-for="crypto">
                      <b-form-select id="crypto"
                                    :plain="true"
                                    v-model.lazy.trim="$v.form.crypto.$model"
                                    :state="chkState('crypto')"
                                    aria-describedby="cryptoFeedback"
                                    autocomplete='given-name'
                                    autofocus
                                    :options="crypto">
                      </b-form-select>
                      <b-form-invalid-feedback id="cryptoFeedback" >
                        <span v-if="!$v.form.crypto.required">- Asset Kripto tidak boleh kosong.</span><br>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group id="setup"
                      label="Setup Margin By"
                      label-for="setup">
                      <b-form-select id="setup"
                                    :plain="true"
                                    v-model.lazy.trim="$v.form.setup.$model"
                                    :state="chkState('setup')"
                                    @change="setupDefault"
                                    aria-describedby="paymentMethodFeedback"
                                    autocomplete='given-name'
                                    autofocus
                                    :options="[
                                      {value: 'default', text: 'By Default'},
                                      {value: 'cdate', text: 'By Customize Date'}]">
                      </b-form-select>
                      <!-- <b-form-invalid-feedback id="paymentMethodFeedback" v-for="(error , index) in errors.form.method" :key="index">
                        - {{ error }} <br>
                      </b-form-invalid-feedback> -->
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-if="this.form.setup == 'cdate'">
                    <div>
                      <b-form-group
                        class="mb-3"
                        label="Date :"
                        label-for="daterange"
                      >
                        <b-input-group
                        >
                          <date-picker
                            v-model="date"
                            format="YYYY-MM-DD HH:mm"
                            type="datetime"
                            lang="en"
                            value-type="format"
                            :not-before="min"
                            :not-after="max"
                            placeholder="YYYY-MM-DD HH:mm"
                          ></date-picker>
                          <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row>
                    <b-col>
                        <b-form @submit.prevent="addPricing" novalidate>
                          <b-form-group id="type"
                            v-if="this.form.setup == 'cdate'"
                            label="Type Pricing"

                            label-for="type">
                            <b-form-select id="type"
                                          :plain="true"
                                          v-model.lazy.trim="$v.form.secondType.$model"
                                          :state="chkState('secondType')"
                                          @change="setDefaultPricing"
                                          aria-describedby="secondTypeFeedback"
                                          autocomplete='given-name'
                                          autofocus
                                          :options="[{value: '', text:'Pilih Tipe Pricing'},
                                                            {value: 'fixed', text: 'Fixed'},
                                                            {value: 'dynamic', text: 'Dynamic'}]">
                            </b-form-select>
                            <b-form-invalid-feedback id="secondTypeFeedback">
                              <span v-if="!$v.form.secondType.required">- Tipe pricing tidak boleh kosong!</span>
                            </b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="amountInputCombinationLvType"
                                          v-if="this.form.secondType == 'fixed'"
                                          label="Amount (Rp)"
                                          label-for="amount">
                              <b-form-input id="amount"
                                          type="number"
                                          v-model.lazy.trim="$v.form.amount.$model"
                                          :state="chkState('amount')"
                                          aria-describedby="amountFeedback"
                                          placeholder="0"
                                          autocomplete='given-name'
                                          autofocus />
                              <b-form-invalid-feedback id="amountFeedback" >
                                <span v-if="!$v.form.amount.required">- Amount tidak boleh kosong!</span><br>
                                <span v-if="!$v.form.amount.numeric">- Amount harus berupa angka valid.</span>
                              </b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="duration"
                                          v-if="this.form.secondType"
                                          label="Duration"
                                          label-for="duration">
                              <b-form-input id="duration"
                                          type="number"
                                          v-model.lazy.trim="$v.form.duration.$model"
                                          :state="chkState('duration')"
                                          aria-describedby="durationFeedback"
                                          placeholder="0"
                                          autocomplete='given-name'
                                          autofocus />
                              <b-form-invalid-feedback id="durationFeedback" >
                                <span v-if="!$v.form.duration.required">- Durasi tidak boleh kosong!</span><br>
                                <span v-if="!$v.form.duration.numeric">- Durasi harus berupa angka valid.</span>
                                <span v-if="!$v.form.duration.maxValue">- Maksimal Input durasi 1440 menit.</span>
                              </b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group id="method"
                            v-show="this.form.secondType == 'dynamic' || this.form.setup == 'default'"
                            label="Method"
                            label-for="Method By">
                            <b-form-select id="method_by"
                                          :plain="true"
                                          v-model.lazy.trim="$v.form.method.$model"
                                          :state="chkState('method')"
                                          aria-describedby="methodFeedback"
                                          autocomplete='given-name'
                                          autofocus
                                          :options="[{value: '', text:'Pilih Metode'},{value: 'percentage', text: 'Percentage'},{value: 'amount', text: 'IDR'},{value: 'combination', text:'Combination'}]">
                            </b-form-select>
                            <b-form-invalid-feedback id="methodFeedback">
                              <span v-if="!$v.form.method.required">- Metode tidak boleh kosong!</span><br>
                            </b-form-invalid-feedback>
                          </b-form-group>
                          <b-row v-if="this.form.method == 'amount'">
                              <b-col sm="4">
                                  <b-form-group id="askInputAmount"
                                                  label="Margin Buy (Rp)"
                                                  label-for="ask">
                                      <b-form-input id="askAmount"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.ask_fixed.$model"
                                                  :state="chkState('ask')"
                                                  aria-describedby="askFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="askFeedback" >

                                      <span v-if="!$v.form.ask_fixed.decimal">- Margin Buy harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="bidInputDynamic"
                                                  label="Margin Sell (Rp)"
                                                  label-for="amount">
                                      <b-form-input id="bid_dynamic"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.bid_fixed.$model"
                                                  :state="chkState('bid')"
                                                  aria-describedby="bidFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="bidFeedback" >

                                      <span v-if="!$v.form.bid_fixed.decimal">- Margin Sell harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="feeInputDynamic"
                                                  label="Trading Fee (Rp)"
                                                  label-for="fee">
                                      <b-form-input id="fee_dynamic"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.fee_fixed.$model"
                                                  :state="chkState('fee')"
                                                  aria-describedby="feeFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="feeFeedback" >

                                      <span v-if="!$v.form.fee_fixed.decimal">- Trading fee harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                          </b-row>
                          <b-row v-if="this.form.method == 'percentage'">
                              <b-col sm="4">
                                  <b-form-group id="askInpuPercentage"
                                                  label="Margin Buy (%)"
                                                  label-for="ask">
                                      <b-form-input id="askPercentage"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.ask.$model"
                                                  :state="chkState('ask')"
                                                  aria-describedby="askFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="askFeedback" >

                                      <span v-if="!$v.form.ask.decimal">- Margin Buy harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="bidInput"
                                                  label="Margin Sell (%)"
                                                  label-for="amount">
                                      <b-form-input id="bid_percentage"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.bid.$model"
                                                  :state="chkState('bid')"
                                                  aria-describedby="bidFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="bidFeedback" >

                                      <span v-if="!$v.form.bid.decimal">- Margin Sell harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="feeInput"
                                                  label="Trading Fee (%)"
                                                  label-for="fee">
                                      <b-form-input id="fee"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.fee.$model"
                                                  :state="chkState('fee')"
                                                  aria-describedby="feeFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="feeFeedback" >

                                      <span v-if="!$v.form.fee.decimal">- Trading fee harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                          </b-row>
                          <b-row v-if="this.form.method == 'combination'">
                              <b-col sm="4">
                                  <b-form-group id="askInputCombination"
                                                  label="Margin Buy (%)"
                                                  label-for="ask">
                                      <b-form-input id="ask_combination"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.ask.$model"
                                                  :state="chkState('ask')"
                                                  aria-describedby="askFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="askFeedback" >

                                      <span v-if="!$v.form.ask.decimal">- Margin Buy harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="bidInputCombination"
                                                  label="Margin Sell (%)"
                                                  label-for="amount">
                                      <b-form-input id="bid_combination"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.bid.$model"
                                                  :state="chkState('bid')"
                                                  aria-describedby="bidFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="bidFeedback" >

                                      <span v-if="!$v.form.bid.decimal">- Margin Sell harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="feeInputFixed"
                                                  label="Trading Fee (%)"
                                                  label-for="fee">
                                      <b-form-input id="fee_combination"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.fee.$model"
                                                  :state="chkState('fee')"
                                                  aria-describedby="feeFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="feeFeedback" >

                                      <span v-if="!$v.form.fee.decimal">- Trading fee harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                          </b-row>
                          <b-row v-if="this.form.method == 'combination'">
                              <b-col sm="4">
                                  <b-form-group id="askInputFixed"
                                                  label="Margin Buy (Rp)"
                                                  label-for="ask">
                                      <b-form-input id="ask_fixed"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.ask_fixed.$model"
                                                  :state="chkState('ask_fixed')"
                                                  aria-describedby="askFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="askFeedback" >

                                      <span v-if="!$v.form.ask_fixed.decimal">- Margin Buy harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="bidInputFixed"
                                                  label="Margin Sell (Rp)"
                                                  label-for="amount">
                                      <b-form-input id="amount_fixed"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.bid_fixed.$model"
                                                  :state="chkState('bid_fixed')"
                                                  aria-describedby="bidFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="bidFeedback" >

                                      <span v-if="!$v.form.bid_fixed.decimal">- Margin Sell harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                              <b-col sm="4">
                                  <b-form-group id="feeInputCombination"
                                                  label="Trading Fee (Rp)"
                                                  label-for="fee">
                                      <b-form-input id="fee_combination_amount"
                                                  type="number"
                                                  v-model.lazy.trim="$v.form.fee_fixed.$model"
                                                  :state="chkState('fee_fixed')"
                                                  aria-describedby="feeFeedback"
                                                  placeholder="0"
                                                  autocomplete='given-name'
                                                  autofocus />
                                      <b-form-invalid-feedback id="feeFeedback" >

                                      <span v-if="!$v.form.fee_fixed.decimal">- Trading fee harus berupa angka valid.</span>
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="12" md="10" lg="10">
                              <b-form-group id="otp" label="Insert OTP Code" label-for="otp_code">
                                <b-input-group class="mt-3" id="otp Code">
                                  <b-form-input placeholder="OTP Code" v-model="form.otp"></b-form-input>
                                  <b-input-group-append>
                                    <b-button variant="secondary" :disabled="sended" @click="sendOtp">{{ this.sended === false ? 'Send Code OTP' : 'Resend Code In'}}</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="2" lg="2">
                              <h5 v-if="sended === true" style="margin-top: 30px" class="btn btn-secondary">{{ this.countdown }}</h5>
                            </b-col>
                          </b-row>
                          <b-button class="btn btn-success " type="submit" >Save</b-button>
                      </b-form>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, helpers, numeric, maxValue} from "vuelidate/lib/validators"
import moment from 'moment'
import axios from 'axios'
const decimal = helpers.regex('decimal', /^[+]?([0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?$/)
// const int = helpers.regex('int', /^(\d+(?:[\.\,]\d{2})?)$/)

export default {
    name: 'Index-pricing',
    data() {
        return {
            date: moment().format('YYYY-MM-DD HH:mm'),
            min: moment().format('YYYY-MM-DD HH:mm'),
            max: moment().add(7, 'days').format('YYYY-MM-DD HH:mm'),
            hour: '',
            value: '',
            hours: Array.from({ length: 10 }).map((_, i) => i + 8),
            options: [],
            countdown: 300,
            sended: false,
            crypto: [],
            form: {
              setup: 'default',
              generate_by: '',
              crypto: '',
              secondType: '',
              method: '',
              ask: 0,
              bid: 0,
              fee: 0,
              ask_fixed: 0,
              bid_fixed: 0,
              fee_fixed: 0,
              amount: '',
              duration: '1',
              hour: '',
              otp: ''
            },
            errors: {
                code: '',
                status: '',
                message: []
            },
            pricing: '',
            isLoading: false
        }
    },
    computed: {
        formStr() { return JSON.stringify(this.form, null, 4) },
        isValid() { return !this.$v.form.$anyError },
        isDirty() { return this.$v.form.$anyDirty },
        invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
        form: {
          setup: {
            required
          },
          ask: {
            decimal
          },
          bid: {
            decimal
          },
          fee: {
            decimal
          },
          ask_fixed: {
            decimal
          },
          bid_fixed: {
            decimal
          },
          fee_fixed: {
            decimal
          },
          generate_by: {
            required,
          },
          secondType: {
            required
          },
          crypto: {
            required
          },
          method: {
            required
          },
          hour: {
            required
          },
          amount: {
            required,
            numeric
          },
          duration: {
            required,
            numeric,
            maxValue: maxValue(1440)
          }
        }
    },
    created () {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/crypto-command').then((response) => {
        this.crypto = response.data.data
      })
      let date = new Date();
      date.setDate(date.getDate() + 30);
      Date.prototype.yyyymmdd = function() {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [this.getFullYear(),
                (mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd
              ].join('-');
      };

      let options = [{ value: '', text: '=== Pilih Jam ==='}]
      let hours = new Date()

      for (let index = 0; index <= 23; index++) {
        if (index < hours.getHours()) {
          options.push({
          value: index,
          text: index + ' hour',
          disabled:  true
        })
        } else {
          options.push({
            value: index,
            text: index + ' hour'
          })
        }
      }
      this.options = options
    },
    methods: {
      sendOtp() {
        this.sended = true
        this.$http.post(`send-otp-crypto`).then((res) => {
          let result = res.data.data

          if (result.length !== 0) {
            this.form.otp = result.code
          }
          this.$swal.fire(
            'Success!',
            'Successfully generated the OTP code',
            'success'
          )
          this.countdownOtp()
        })
      },
      countdownOtp() {
        if(this.countdown > 0) {
          setTimeout(() => {
            this.countdown -= 1
            this.countdownOtp()
          }, 1000)
        } else {
          this.sended = false
          this.countdown = 300
        }
      },
      setupDefault(){
        this.form.method = ''
        this.form.ask = 0
        this.form.bid = 0
        this.form.fee = 0
        this.form.ask_fixed = 0
        this.form.bid_fixed = 0
        this.form.fee_fixed = 0
      },
      setDefaultPricing(){
        this.form.method = ''
        this.form.ask = 0
        this.form.bid = 0
        this.form.fee = 0
        this.form.ask_fixed = 0
        this.form.bid_fixed = 0
        this.form.fee_fixed = 0
      },
      notBeforeNine(date) {
        return date.getHours() < 9;
      },
        addPricing() {
            this.isLoading = true
            this.validate()
            return new Promise ((resolve) => {
              this.$http
              .post(`crypto`, {
                setup: this.form.setup,
                method: this.form.method,
                margin_ask: this.form.ask,
                margin_bid: this.form.bid,
                trading_fee: this.form.fee,
                margin_ask_combination: this.form.ask_fixed,
                margin_bid_combination: this.form.bid_fixed,
                trading_fee_combination: this.form.fee_fixed,
                date: this.date,
                hour: this.form.hour,
                type: this.form.secondType,
                duration: this.form.duration,
                amount: this.form.amount,
                otp: this.form.otp,
                crypto: this.form.crypto
              })
              .then((result) => {
                this.isLoading = false
                this.$swal.fire(
                  'Success!',
                  'Pricing was successfully saved.',
                  'success'
                ).then(() =>{
                  this.$router.push("/crypto-pricing");
                })
                resolve(result.data)
              })
              .catch((error) => {
                this.isLoading = false
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status == 422) {
                    this.$toasted.error('Data tidak valid. Mohon periksa kembali isian form yang tersedia.')
                  } else if(this.errors.status == 400) {
                    this.$swal.fire(
                      'Failed!',
                      this.errors.message,
                      'error'
                    )
                  }
                }
              })
            })
        },
        chkState(val) {
            const field = this.$v.form[val]
            return !field.$dirty || !field.$invalid
        },
        findFirstError(component = this) {
            if (component.state === false) {
                if (component.$refs.input) {
                component.$refs.input.focus()
                return true
                }
                if (component.$refs.check) {
                component.$refs.check.focus()
                return true
                }
            }
            let focused = false
            component.$children.some((child) => {
                focused = this.findFirstError(child)
                return focused
            })

            return focused
        },
        validate() {
            this.$v.$touch()
            this.$nextTick(() => this.findFirstError())
            return this.isValid
        },
    },
}
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
